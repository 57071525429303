








































































































































































































import { Vue, Component, Mixins } from 'vue-property-decorator'
import _ from 'lodash'
import moment from 'moment'
import '$ui/icons/computer-fill'
import '$ui/icons/database-fill'
import '$ui/icons/backstage-data-fill'
import '$ui/icons/gantt'
import '$ui/icons/cloud-disk-fill'

// @ts-ignore
import MyDvTitle from '$ui/dv/packages/my-dv-title'
// @ts-ignore
import MyDvMenu from '$ui/dv/packages/my-dv-menu'
// @ts-ignore
import MyDvBox from '$ui/dv/packages/my-dv-box'
// @ts-ignore
import MyDvIcon from '$ui/dv/packages/my-dv-icon'
// @ts-ignore
import MyDvNumber from '$ui/dv/packages/my-dv-number'
// @ts-ignore
import MyDvText from '$ui/dv/packages/my-dv-text'
// @ts-ignore
import MyDvBorder6 from '$ui/dv/packages/my-dv-border6'

// @ts-ignore
import MyDvGeo from '$ui/dv/packages/my-dv-geo'
// @ts-ignore
import MyDvGeoGl from '$ui/dv/packages/my-dv-geo-gl'

// @ts-ignore
import MyBreadcrumb from '$ui/components/my-breadcrumb'

// @ts-ignore
import MyDvLine from '$ui/dv/packages/my-dv-line'

// @ts-ignore
import MyDvList from '$ui/dv/packages/my-dv-list'

// @ts-ignore
import MyDvLoading from '$ui/dv/packages/my-dv-loading'

// @ts-ignore
import geoArray from '$ui/charts/geo/index.json'

// @ts-ignore
import dateFormat from '$ui/utils/date'

import MyCommon from '@/mixins/common'

import Map from './map.vue'

import UserModal from './list/user.vue'

import VipModal from './list/vip.vue'

// @ts-ignore
import china from '$ui/charts/geo/china.json'

@Component({
  components: {
    MyDvTitle,
    MyDvMenu,
    MyDvBox,
    MyDvIcon,
    MyDvNumber,
    MyDvText,
    MyDvBorder6,
    MyDvGeo,
    MyDvGeoGl,
    MyBreadcrumb,
    MyDvLine,
    MyDvList,
    MyDvLoading,
    Map,
    UserModal,
    VipModal,
  },
})
export default class extends Mixins(MyCommon) {
  userModalVisible: boolean = false
  userModalParams: any = {}
  userModalTitle: string = ''

  vipModalVisible: boolean = false
  vipModalParams: any = {}
  vipModalTitle: string = ''

  menus = [
    { text: '场馆', to: '/v3/' },
    { text: '用户', to: '/v3/user' },
    { text: '运营' },
  ]
  defaultMenuActive = 0

  stats = [
    {
      label: '全部用户',
      value: 0,
      icon: 'icon-computer-fill',
      key: 'user_count',
      suffix: '人',
    },
    {
      label: '全部场馆会员',
      value: 0,
      icon: 'icon-database-fill',
      key: 'vip_count',
      suffix: '人',
    },
    {
      label: '今日新增用户',
      value: 0,
      icon: 'icon-backstage-data-fill',
      key: 'today_user_count',
      suffix: '人',
    },
    {
      label: '今日新增会员',
      value: 0,
      icon: 'icon-gantt',
      key: 'today_vip_count',
      suffix: '人',
    },
    {
      label: '今日下单用户',
      value: 0,
      icon: 'icon-backstage-data-fill',
      key: 'today_buy_user',
      suffix: '人',
    },
    {
      label: '今日打球用户',
      value: 0,
      icon: 'icon-gantt',
      key: 'today_play_user',
      suffix: '人',
    },
  ]

  breadcrumb = [{ label: '全国', geo: 'china.json' }]

  mapName = 'china.json'

  type = {
    人口数量: 'effectScatter',
  }

  lineMode: string = 'mode1'

  listChart: any = {
    columns: ['日期', '日活', '周活', '月活'],
    rows: [],
  }

  maploading: boolean = false

  list: any = {
    columns: [
      { label: '日期', prop: 'd' },
      { label: '日活', prop: 'c_dau' },
      { label: '周活', prop: 'c_wau' },
      { label: '日活/周活比', prop: 'c_d_wau' },
      { label: '月活', prop: 'c_mau' },
      { label: '日活/月活比', prop: 'c_d_mau' },
    ],
    rows: [],
  }

  lineExtend: any = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
    },
  }

  showUserModal(title: string, key: string) {
    this.userModalTitle = title
    if (key == 'vip_count') {
      this.userModalParams = {
        user_type: 1,
      }
    } else if (key == 'today_user_count') {
      this.userModalParams = {
        created_start: moment().format('YYYY-MM-DD') + ' 00:00:00',
      }
    } else if (key == 'today_vip_count') {
      this.userModalParams = {
        user_type: 1,
        created_start: moment().format('YYYY-MM-DD') + ' 00:00:00',
      }
    } else if (key == 'today_buy_user') {
      this.userModalParams = {
        latest_order_time1: moment().format('YYYY-MM-DD') + ' 00:00:00',
        latest_order_time2: moment().format('YYYY-MM-DD') + ' 23:59:59',
      }
    } else if (key == 'today_play_user') {
      this.userModalParams = {
        latest_play_time1: moment().format('YYYY-MM-DD') + ' 00:00:00',
        latest_play_time2: moment().format('YYYY-MM-DD') + ' 23:59:59',
      }
    } else {
      this.userModalParams = {}
    }
    this.userModalVisible = true
  }

  hideUserModal() {
    this.userModalVisible = false
  }

  showVipModal() {}

  hideVipModal() {
    this.vipModalVisible = false
  }

  tabChange(key: string) {
    this.lineMode = key

    if (key == 'mode2') {
      this.userActivityPlay()
    } else if (key == 'mode3') {
      this.userActivity()
    } else {
      this.userActivityBill()
    }
  }

  mounted() {
    this.userSummary()

    this.userActivityBill()

    this.areaUserCount()
  }

  async userSummary() {
    const res = await this.$store.dispatch('datav/userSummary', {})
    if (res && res.Data) {
      this.stats[0].value = res.Data.user_count
      this.stats[1].value = res.Data.vip_count
      this.stats[2].value = res.Data.today_user_count
      this.stats[3].value = res.Data.today_vip_count
      this.stats[4].value = res.Data.today_buy_user
      this.stats[5].value = res.Data.today_play_user
    }
  }

  async daysLoader() {
    const res = await this.$store.dispatch('statistics/getUsers', {})
    if (res && res.Data) {
      let rows: any[] = []

      for (const v of res.Data.categories) {
        rows.push([v, res.Data.data[v]])
      }

      return {
        columns: ['日期', '数量'],
        rows: rows,
      }
    }
  }

  async userActivity() {
    this.loading = true
    const res = await this.$store.dispatch('statistics/userActivity', {})
    this.loading = false
    if (res && res.Data) {
      let rows: any[] = []

      let rows1: any[] = []

      const data: any = _.orderBy(res.Data, ['d'], ['desc'])

      for (const obj of data) {
        rows.push([
          obj.d,
          obj.c_dau,
          obj.c_wau,
          obj.c_d_wau,
          obj.c_mau,
          obj.c_d_mau,
        ])
      }

      for (const obj of res.Data) {
        rows1.push([obj.d, obj.c_dau, obj.c_wau, obj.c_mau])
      }

      this.list.rows = rows
      this.listChart.rows = rows1
    }
  }

  async userActivityPlay() {
    this.loading = true
    const res = await this.$store.dispatch('statistics/userActivityPlay', {})
    this.loading = false
    if (res && res.Data) {
      let rows: any[] = []

      let rows1: any[] = []

      const data: any = _.orderBy(res.Data, ['d'], ['desc'])

      for (const obj of data) {
        rows.push([
          obj.d,
          obj.c_dau,
          obj.c_wau,
          obj.c_d_wau,
          obj.c_mau,
          obj.c_d_mau,
        ])
      }

      for (const obj of res.Data) {
        rows1.push([obj.d, obj.c_dau, obj.c_wau, obj.c_mau])
      }

      this.list.rows = rows
      this.listChart.rows = rows1
    }
  }

  async userActivityBill() {
    this.loading = true
    const res = await this.$store.dispatch('statistics/userActivityBill', {})
    this.loading = false
    if (res && res.Data) {
      let rows: any[] = []

      let rows1: any[] = []

      const data: any = _.orderBy(res.Data, ['d'], ['desc'])

      for (const obj of data) {
        rows.push([
          obj.d,
          obj.c_dau,
          obj.c_wau,
          obj.c_d_wau,
          obj.c_mau,
          obj.c_d_mau,
        ])
      }

      for (const obj of res.Data) {
        rows1.push([obj.d, obj.c_dau, obj.c_wau, obj.c_mau])
      }

      this.list.rows = rows
      this.listChart.rows = rows1
    }
  }

  json({ map }: any) {
    // @ts-ignore
    this.$refs.geo.loading = true
    const [path, file] = map.split('/')

    if (!file) {
      return import('$ui/charts/geo/china' + '.json')
    }

    return path === 'province'
      ? import('$ui/charts/geo/province/' + file)
      : import('$ui/charts/geo/city/' + file)
  }

  map: any = {
    json: china,
    type: 'bar3D',
    columns: ['省份', '用户数量'],
    rows: [],
    visual: {
      show: true,
      color: ['#1de2ff', 'rgba(24,144,255,0.13)'],
      max: 5000,
    },
    extend: {
      tooltip: {
        show: true,
      },
    },
  }

  async areaUserCount() {
    this.maploading = true
    const res = await this.$store.dispatch('datav/areaUserCount', {})
    this.maploading = false
    if (res && res.Data) {
      let rows: any[] = []
      for (const obj of res.Data) {
        rows.push([obj.name, obj.ccount])
      }

      this.map.rows = rows
    }
  }

  get activeIndex() {
    return this.breadcrumb.length - 1
  }

  handleClick(params: any) {
    const name = params.name.replace('市', '')
    const geoItem = geoArray.find((item: any) => item.name === name)
    if (geoItem) {
      this.mapName = geoItem.geo
      this.breadcrumb.push({
        label: params.name,
        ...geoItem,
      })
    }
  }

  handleMapRegister() {
    // @ts-ignore
    this.$refs.geo.loading = false
  }

  handleMenuClick(item: any, index: number) {
    this.breadcrumb.splice(index + 1)
    this.mapName = item.geo
  }

  loader({ name }: any) {
    return Promise.resolve({
      columns: ['省份', '人口数量'],
      rows: [
        ['广州', 225344],
        ['广州市', 1323],
      ],
    })
  }
}
