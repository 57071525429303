





import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'

import MyList from '@/mixins/list'

import MyDialog from '@/components/dialog.vue'

import UserList from '@/views/player/userlist.vue'

@Component({
  components: {
    MyDialog,
    UserList,
  },
})
export default class extends Mixins(MyList) {
  @Prop() title!: string

  @Prop() params?: any
}
